import React from "react"
import { Benefit, Main, Row, Text } from "../../../components/atomics"
import Page from "../../../components/Pages/Page"
import colors from "../../../colors"
import { useState } from "react"
import { useEffect } from "react"
import { getQueryParam } from "../../../utils/query"
import { formatToMoney } from "../../../utils/cupom"

export const LIMITS_FREE = {
  wallets: 5,
  categories: 25,
  budgets: 5,
  goals: 5,
  tags: 5,
  cards: 1,
};

const benefits = [
  {
    title: `Plataforma Web`,
    description: `Sendo premium, você tem acesso a versão Web para gerenciar seus Gastos, Receitas, Carterias,Orçamentos,Metas e etc...`,
  },
  {
    title: `Backup & Sincronização`,
    description: `Sendo premium, todos os seus dados (Carterias,Orçamentos,Metas,Transações, etc...) são salvos em núvem. Assim você não irá perder suas informações caso mudar de celular ou desinstalar o aplicativo.`,
  },
  {
    title: `Sem Anúncios`,
    description: `Sendo premium, você utiliza o aplicativo sem visualizar anúncios.`,
  },
  {
    title: `Estatísticas Exclusivas`,
    description: `Sendo premium, você tem acesso a estatísticas exclusivas sobre suas carterias, categorias, tags, orçamentos e muito mais!`,
  },
  // {
  //   title: `Notificações em Transações`,
  //   description: `Sendo premium, você pode adicionar notificações em suas transações futuras.`,
  // },
  {
    title: `Tags Ilimitadas`,
    description: `Sendo premium, você pode criar Tags ilimitadas. Com a versão Free, você pode criar até ${LIMITS_FREE.tags} Tags`,
  },
  {
    title: `Carteiras Ilimitadas`,
    description: `Sendo premium, você pode criar Carteiras ilimitadas. Com a versão Free, você pode ter até ${LIMITS_FREE.wallets} Carteiras.`,
  },
  {
    title: `Orçamentos Ilimitados`,
    description: `Sendo premium, você pode criar Orçamentos ilimitadas. Com a versão Free, você pode ter até ${LIMITS_FREE.budgets} Orçamentos.`,
  },
  {
    title: `Metas Ilimitadas`,
    description: `Sendo premium, você pode criar Metas ilimitadas. Com a versão Free, você pode ter até ${LIMITS_FREE.goals} Metas.`,
  },
  {
    title: `Categorias Ilimitadas`,
    description: `Sendo premium, você pode criar Categorias ilimitadas. Com a versão Free, você pode ter até ${LIMITS_FREE.categories} Categorias.`,
  },
  {
    title: `Funcionalidades Futuras`,
    description: `Sendo premium você terá acesso a todas as funcionalidades que serão desenvolvimentas futuramente...`,
  },
]

const plans = [
  {
    label: "12 MESES",
    price: 79.99,
    data: [
      {
        key: "Cartão de Crédito ou Google/Apple Pay",
        description:
          "Clique no link abaixo, para pagar usando Cartão de Crédito, Apple Pay ou Google Pay. No campo email, informe o seu email cadastrado no app, para liberação do acesso premium.",
        titleButton: "Pagar com Cartão ou Google/Apple Pay",
        url: "https://buy.stripe.com/7sI29V6qd9Gd2gU9AS?locale=pt-BR",
      },
      // {
      //   key: "PIX",
      //   description:
      //     "Clique no link abaixo, para pagar com o PIX. Você pode pagar de 3 formas: Ler o QR Code, Copiar o código do QR Code, ou com a Chave Pix.",
      //   titleButton: "Pagar com PIX",
      //   url: "https://nubank.com.br/pagar/uwbk/9I1AnxVyy9",
      // },
    ],
  },
  // {
  //   label: "36 MESES",
  //   price: 159.99,
  //   data: [
  //     {
  //       key: "CARTÃO, MERCADO PAGO OU PAYPAL",
  //       description: 'Clique no link abaixo, para pagar com o Mercado Pago. Pelo mercado pago, você pode pagar com o seu saldo do mercado pago, paypal, pix ou cartão.',
  //       titleButton: 'Pagar com Mercado Pago',
  //       url: "https://mpago.la/1j6eR2P",
  //     },
  //     {
  //       key: "PIX",
  //       description: 'Clique no link abaixo, copie o código do QR Code, abra o app do seu banco, busque a opção Pix Copie e Cola e cole o código copiado para pagar.',
  //       titleButton: 'Pagar com PIX',
  //       url: "https://nubank.com.br/pagar/uwbk/gzZRy9Ni46",
  //     },
  //     {
  //       key: "TRANSFERÊNCIA BANCÁRIA",
  //       description: 'Agência: 0001 | Conta: 1441568-2 | Banco: Nubank | Titular: Rogerd | Valor: 159.99',
  //     },
  //   ],
  // },
  {
    label: "VITALÍCIO",
    price: 199.90,
    data: [
      {
        key: "Cartão de Crédito ou Google/Apple Pay",
        description:
          "Clique no link abaixo, para pagar usando Cartão de Crédito, Apple Pay ou Google Pay. No campo email, informe o seu email cadastrado no app, para liberação do acesso premium.",
        titleButton: "Pagar com Cartão ou Google/Apple Pay",
        url: "https://buy.stripe.com/fZecOz01P2dLf3G14p?locale=pt-BR",
      },
      // {
      //   key: "PIX",
      //   description:
      //     "Clique no link abaixo, copie o código do QR Code, abra o app do seu banco, busque a opção Pix Copie e Cola e cole o código copiado para pagar.",
      //   titleButton: "Pagar com PIX",
      //   url: "https://nubank.com.br/pagar/uwbk/WA2gJlhaCt",
      // },
    ],
  },
]

function PremiumPromotion() {
  const [plan, setPlan] = useState(plans[0])
  const [pay, setPay] = useState(plans[0].data[0])
  const [cupom, setCupom] = useState(null)
  const [utm, setUtm] = useState("")

  useEffect(() => {
    setCupom(getQueryParam("cupom"))
    setUtm(getQueryParam("utm_source"))
  }, [])

  const getUrlCheckout = url => {
    if (!cupom) return url
    if (url.includes("stripe")) {
      return url + "&prefilled_promo_code=" + cupom
    }
  }

  function getPrices(basePrice) {
    const discontPercentage = cupom
      ? Number(cupom.replace(/\D/g, "") || "0")
      : 0
    const price = basePrice
    return {
      price: formatToMoney(price),
      newPrice: cupom
        ? formatToMoney(price * (1 - discontPercentage / 100))
        : formatToMoney(price),
      discontPercentage,
    }
  }
  function getInfo() {
    const discontPercentage = cupom
      ? Number(cupom.replace(/\D/g, "") || "0")
      : 0
    return {
      URL_CHECKOUT: getUrlCheckout(pay.url),
      textCupom: cupom
        ? discontPercentage
          ? `${discontPercentage}% DE DESCONTO! APROVEITE! 🔥`
          : null
        : null,
      discontPercentage,
      ...getPrices(plan.price),
    }
  }

  const {
    URL_CHECKOUT,
    textCupom,
    discontPercentage,
  } = getInfo()

  return (
    <Page
      titlePage="Premium | FLYNOW - FINANÇAS"
      title="FLYNOW - FINANÇAS"
      pageId="premium_finances"
      description={
        "Torne-se Premium e tenha acesso a ferramentas exclusivas!"
      }
      logo={require(`../../../assets/images/app/finances/logo.png`)}

    >
      <Main>
        <div>
          <h1>Torne-se Premium e tenha acesso a ferramentas exclusivas!</h1>

          <Text style={{ marginTop: 10 }}>
            Com o plano premium, você tem acesso a diversas ferramentas para te
            auxiliar a ser mais produtivo, manter seus hábitos e atingir seus
            objetivos! <br />
            <br />
          </Text>

          {/* <br /> */}

          {textCupom && (
            <h4 style={{ color: "red", textAlign: "center", marginBottom: 20 }}>
              {textCupom}
            </h4>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3>Benefícios premium</h3>
            {benefits.map(i => (
              <Benefit>
                <Text
                bold
                style={{marginBottom:15}}
                >
                  {i.title}
                </Text>
                <Text
                >
                  {i.description}
                </Text>
                {i.img && <img src={i.img.uri} height="150" />}
              </Benefit>
            ))}
          </div>

          <Text
          bold
            style={{ fontSize: "0.9rem",  marginBottom: 10 }}
          >
            *Informações para adquirir o premium:
          </Text>
          <Text style={{ fontSize: "0.9rem", lineHeight: "1.3rem" }}>
            {" "}
            ✔️ Escolha o plano que deseja assinar e o método de pagamento.
            Realize o pagamento conforme as intruções para o pagamento do método
            escolhido.
          </Text>
          <Text style={{ fontSize: "0.9rem", lineHeight: "1.3rem" }}>
            {" "}
            ✔️ Se tiver qualquer dúvida, entre em contato com a gente!{" "}
          </Text>

          <br />
          <h4>Selecione o plano:</h4>
          <Row>
            {plans.map(i => (
              <div
                onClick={() => {
                  setPlan(i)
                  setPay(i.data[0])
                }}
                style={{
                  padding: "10px 20px",
                  cursor: "pointer",
                  borderRadius: 10,
                  marginRight: 10,
                  marginTop: 5,
                  border:
                    i.label === plan.label
                      ? `3px solid ${colors.primary}`
                      : "1px solid #222",
                  color: i.label === plan.label ? colors.primary : undefined,
                }}
              >
                <h4>{i.label}</h4>
                {discontPercentage ? (
                  <s>
                    <h3
                      style={{
                        fontSize: discontPercentage ? 20 : 28,
                        textAlign: "center",
                      }}
                    >
                      {i.price}
                    </h3>
                  </s>
                ) : (
                  <h3>{i.price}</h3>
                )}
                {discontPercentage >0 && (
                  <h3 style={{ fontSize: 28 }}>
                    {getPrices(i.price).newPrice}
                  </h3>
                )}
              </div>
            ))}
          </Row>
          <h4 style={{ marginTop: 15 }}>Selecione o método de pagamento:</h4>
          {plan.data.map(i => (
            <div
              onClick={() => setPay(i)}
              style={{
                cursor: "pointer",
                padding: "5px 10px",
                borderRadius: 10,
                marginRight: 10,
                marginTop: 5,
                border:
                  i.key === pay.key
                    ? `2px solid ${colors.primary}`
                    : "1px solid #222",
                color: i.key === pay.key ? colors.primary : undefined,
              }}
            >
              <Text style={{ fontSize: "0.9rem", lineHeight: "1.1rem" }}>
                {i.key}
              </Text>
            </div>
          ))}

          <h4 style={{ marginTop: 15 }}>Instruções para o pagamento:</h4>
          <div style={{ marginTop: 5 }}>
            <Text>
              {pay.description && (
                <Text
                  style={{
                    fontSize: "0.9rem",
                    lineHeight: "1.1rem",
                    marginBottom: 10,
                  }}
                >
                  {pay.description}
                </Text>
              )}
              {pay.url && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "10px 30px",
                      backgroundColor: colors.green,
                      borderRadius: 22,
                      color: "#fff",
                      fontFamily: "Poppins-Bold",
                      border: "none",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <a
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins-Bold",
                      }}
                      href={URL_CHECKOUT}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {pay.titleButton}
                    </a>
                  </div>
                </div>
              )}
              {pay.obs && <h6>{pay.obs}</h6>}
              {pay.key === "PIX" && (
                <>
                  <Text
                    style={{
                      fontSize: "0.9rem",
                      lineHeight: "1.1rem",
                      marginTop: 10,
                    }}
                  >
                    Após o pagamento, nos envie o comprovante por Email ou
                    WhatsApp e informe o seu email cadastrado no app para a
                    liberação dos recursos premium para a sua conta.
                  </Text>
                </>
              )}
            </Text>
          </div>

          {pay.key === "PIX" && (
            <>
              <h4 style={{ marginTop: 15, marginBottom: 10 }}>
                Enviar comprovante via:
              </h4>
              <Text center style={{ fontSize: "0.95rem" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: 200,
                      padding: "8px",
                      backgroundColor: colors.primary,
                      borderRadius: 22,
                      color: "#fff",
                      fontFamily: "Poppins-Bold",
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins-Bold",
                      }}
                      href={
                        "https://api.whatsapp.com/send/?phone=5531999107753"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      WhatsApp
                    </a>
                  </div>
                  <div
                    style={{
                      marginTop: 5,
                      width: 200,
                      padding: "8px",
                      backgroundColor: colors.primary,
                      borderRadius: 22,
                      color: "#fff",
                      fontFamily: "Poppins-Bold",
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins-Bold",
                      }}
                      href={"mailto:productivity@appflynow.com"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Email
                    </a>
                  </div>
                  <Text style={{ fontSize: "0.9rem" }}>
                    productivity@appflynow.com
                  </Text>
                </div>
              </Text>
            </>
          )}
        </div>
      </Main>
    </Page>
  )
}

export default PremiumPromotion
